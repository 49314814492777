import { getLocalStorageItem } from "../Utils";

export const Authendpoint = {
  LoginEmail: "api/v1/auth/login-email",
  LoginMobile: "api/v1/auth/login-mobile",
  forgorPassword: "api/v1/auth/forgot-password",
  forgorPasswordOtpVerify: "api/v1/auth/forget-password-otp-verify",
  register: "api/v1/auth/register",
  logout: "api/v1/user/user-logout",
  resendOtp: "api/v1/auth/resend-otp",
  verifyOtp: "api/v1/auth/verify-otp",
};
export const Globalendpoints = {
  profile:"api/v1/user/profile",
  walletBal:"api/v1/user/wallet-balance",
  placeBet:"api/v1/aviator/betting",
  aviatorPlayHistory:"api/v1/aviator/aviatorPlayHistory",
  latestBets:"api/v1/aviator/latestBets",
  topBets:"api/v1/aviator/fetchTopBets"
};
