import { HashRouter, useRoutes } from "react-router-dom";
import "./App.css";
import { PrivateAuth } from "./Routes/PrivateRoutes";
import { PublicRoutes } from "./Routes/Publicroutes";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainerMsg } from "./Utils";

function App() {
  const AviatorRoute = [...PublicRoutes(), ...PrivateAuth()];
  const AviatorRouteConfig = () => useRoutes(AviatorRoute);

  return (
    <>
      <HashRouter>
          <AviatorRouteConfig />
      </HashRouter>
      <ToastContainerMsg />
    </>
  );
}

export default App;
