import {
  Get,
  Post,
  getAPIUrl,
} from "./apiMethod";
import { Authendpoint, Globalendpoints } from "./global";

export const userLoginEmail = (data, param = "") => {
  const url = getAPIUrl(Authendpoint.LoginEmail, param);
  return Post(url, data, false);
};
export const userLoginMobile = (data, param = "") => {
  const url = getAPIUrl(Authendpoint.LoginMobile, param);
  return Post(url, data, false);
};
export const userRegsiter = (data, param = "") => {
  const url = getAPIUrl(Authendpoint.register, param);
  return Post(url, data, false);
};
export const ResendOtp = (data, param = "") => {
  const url = getAPIUrl(Authendpoint.resendOtp, param);
  return Post(url, data, false);
};
export const ForgotPassword = (data, param = "") => {
  const url = getAPIUrl(Authendpoint.forgorPassword, param);
  return Post(url, data, false);
};
export const VerifyOtp = (data, param = "") => {
  const url = getAPIUrl(Authendpoint.verifyOtp, param);
  return Post(url, data, false);
};
export const GetUserProfile = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.profile, param);
  return Get(url, data);
};
export const GetWallletBal = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.walletBal, param);
  return Get(url, data);
};
export const PlaceBet = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.placeBet, param);
  return Post(url, data);
};
export const AviatorPlayHistory = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.aviatorPlayHistory, param);
  return Get(url, data);
};
export const LatesBets = ( param = "") => {
  const url = getAPIUrl(Globalendpoints.latestBets, param);
  return Get(url);
};
export const TopBets = ( param = "") => {
  const url = getAPIUrl(Globalendpoints.topBets, param);
  return Get(url);
};
