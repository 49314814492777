import React, { useState } from "react";
import { Images } from "../../Assets";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Button, Form, Input, Spin } from "antd";
import { ResendOtp, VerifyOtp } from "../../ApiServices/Apifun";
import { useLocation, useNavigate } from "react-router-dom";
import { dispatchtoast, setLocalStorageItem } from "../../Utils";

const VerifyOtpScreen = () => {
  const location = useLocation();
  const { mobile, token } = location.state || {};
  const [btnLoading, setBtnLoading] = useState(false);
  const[pageLoader,setPageLoader]=useState(false)
  const[newToken,setNewToken]=useState(null)
  const navigate = useNavigate();
  const handleFinish = (val) => {
    setBtnLoading(true);
    VerifyOtp({
      otp: val.otp,
      verificationToken:newToken?? token,
      mobile: mobile,
    })
      .then((res) => {
        if (res.status) {
          setLocalStorageItem("access_token", res.access_token);
          setLocalStorageItem("isLoggedIn", true);
          setLocalStorageItem("referesh_token", res.referesh_token);
          setLocalStorageItem("userDetail", res.user);
          navigate("/main")
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setBtnLoading(false));
  };
  const handleResend=()=>{
    setPageLoader(true)
    ResendOtp({
        mobile:mobile,
        verificationToken:token
    }).then(res=>{
        if(res.status){
            setNewToken(res.verificationToken)
            dispatchtoast(res.message)
        }
    }).catch(err=>console.log(err)).finally(()=>setPageLoader(false))
  }
  return (
    <>
     <Spin spinning={pageLoader}>
     <div className="flex justify-center items-center h-screen">
        <div className="bg-[#E14342] rounded-sm shadow-md shadow-red-300  px-8 py-4">
          <div className="flex mt-4 justify-center items-center">
            <LazyLoadImage src={Images.Logo} className="w-20" alt="logo" />
          </div>
          <div>
            <Form onFinish={(val) => handleFinish(val)}>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: <p className="text-red-900">Enter OTP</p>,
                  },
                ]}
                name={"otp"}
                className="w-full m-1"
                label={<p className="font-semibold text-white p-0">OTP</p>}
                labelCol={{ span: 24 }}
              >
                <Input maxLength={6}
                  placeholder="Enter OTP"
                  className="border-none hover:border-none"
                />
              </Form.Item>
              <div className="flex justify-end items-center">
                <p onClick={handleResend} className="text-white font-semibold cursor-pointer">Resend Otp</p>
              </div>
              <Form.Item className="w-full">
                <Button
                  loading={btnLoading}
                  htmlType="submit"
                  style={{ background: "#181C27", color: "white" }}
                  rootClassName="bg-primary hover:bg-primary"
                  className="w-full mt-4 bg-primary shadow-none hover:text-white text-white border-none"
                >
                  Verify Otp
                </Button>
              </Form.Item>
              <div className="flex justify-center select-none items-center">
                <p onClick={() => navigate(-1)} className="text-white">
                  Go Back
                </p>
              </div>
            </Form>
          </div>
        </div>
      </div>
     </Spin>
    </>
  );
};

export default VerifyOtpScreen;
