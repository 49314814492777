import React, { useState } from "react";
import { Images } from "../../Assets";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Button, Form, Input } from "antd";
import { dispatchtoast, emailPattern, mobilePattern } from "../../Utils";
import {  userRegsiter } from "../../ApiServices/Apifun";
import {  useNavigate } from "react-router-dom";

const Register = () => {
  const [btnLoading, setBtnLoading] = useState(false);
  const [form] = Form.useForm();

  const navigate = useNavigate();
  const validatePassword = (rule, value) => {
    const { getFieldValue } = form;
    if (value && value !== getFieldValue("password")) {
      return Promise.reject(<p className="text-red-900">The passwords do not match!</p>);
    }
    return Promise.resolve();
  };
  const handleFinish = (val) => {
    let data = { ...val };
    data.tandc = 1;
    setBtnLoading(true);
    userRegsiter(data)
      .then((res) => {
        if (res.status) {
            navigate("/verify-otp",{state:{
                mobile:val.mobile,
                token:res.verificationToken
            }})
            dispatchtoast(res.message)
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setBtnLoading(false));
  };
  return (
    <>
      <div className="flex justify-center items-center h-screen">
        <div className="bg-[#E14342] m-4 rounded-sm shadow-md shadow-red-300  px-8 py-4">
          <div className="flex mt-4 justify-center items-center">
            <LazyLoadImage src={Images.Logo} className="w-20" alt="logo" />
          </div>
          <div>
            <Form form={form} onFinish={(val) => handleFinish(val)}>
              <div className="grid grid-cols-1 sm:grid-cols-2 place-items-center gap-2">
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: <p className="text-red-900">Enter Name</p>,
                    },
                  ]}
                  name={"name"}
                  className="w-full"
                  label={<p className="font-semibold text-white p-0">Name</p>}
                  labelCol={{ span: 24 }}
                >
                  <Input
                    placeholder="Enter Name"
                    className="border-none hover:border-none"
                  />
                </Form.Item>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: (
                        <p className="text-red-900">Enter Mobile Number</p>
                      ),
                    },
                    {
                      pattern: mobilePattern,
                      message: (
                        <p className="text-red-900">
                          Enter Valid Mobile Number
                        </p>
                      ),
                    },
                  ]}
                  name={"mobile"}
                  className="w-full"
                  label={<p className="font-semibold text-white p-0">Mobile</p>}
                  labelCol={{ span: 24 }}
                >
                  <Input
                    placeholder="Enter Mobile Number"
                    className="border-none hover:border-none"
                  />
                </Form.Item>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: <p className="text-red-900">Enter Email</p>,
                    },
                    {
                      pattern: emailPattern,
                      message: (
                        <p className="text-red-900">Enter valid Email</p>
                      ),
                    },
                  ]}
                  name={"email"}
                  className="w-full"
                  label={<p className="font-semibold text-white p-0">Email</p>}
                  labelCol={{ span: 24 }}
                >
                  <Input
                    placeholder="Enter Email"
                    className="border-none hover:border-none"
                  />
                </Form.Item>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: <p className="text-red-900">Enter Password</p>,
                    },
                  ]}
                  name={"password"}
                  className="w-full"
                  label={
                    <p className="font-semibold text-white p-0">Password</p>
                  }
                  labelCol={{ span: 24 }}
                >
                  <Input
                    placeholder="Enter Password"
                    className="border-none hover:border-none"
                  />
                </Form.Item>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: (
                        <p className="text-red-900">Enter Confirm Password</p>
                      ),
                    },
                    { validator: validatePassword },
                  ]}
                  dependencies={["password"]}
                  name={"confirm_password"}
                  className="w-full"
                  label={
                    <p className="font-semibold text-white p-0">
                      Confirm Passowrd
                    </p>
                  }
                  labelCol={{ span: 24 }}
                >
                  <Input
                    placeholder="Enter Confirm Password"
                    className="border-none hover:border-none"
                  />
                </Form.Item>
              </div>

              <Form.Item className="w-full">
                <Button
                  loading={btnLoading}
                  htmlType="submit"
                  style={{ background: "#181C27", color: "white" }}
                  rootClassName="bg-primary hover:bg-primary"
                  className="w-full mt-4 bg-primary shadow-none hover:text-white text-white border-none"
                >
                  Regsiter
                </Button>
              </Form.Item>
              <div className="flex justify-center select-none items-center">
                <p className="text-white">
                  Already have an account?{" "}
                  <span
                    onClick={() => navigate("/")}
                    className="cursor-pointer"
                  >
                    Login
                  </span>
                </p>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;
