import React from "react";
import { Navigate, Outlet,  } from "react-router-dom";
// import ProjectLayout from "../ProjectLayout";
import { getLocalStorageItem } from "../Utils";

function ProtectedAuth() {
  const isAuthenticated = () => {
    const user = getLocalStorageItem("isLoggedIn");
    return user;
  };


  return isAuthenticated() ? (
    <>
      {" "}
      {/* <ProjectLayout> */}
          <Outlet />
      {/* </ProjectLayout> */}
    </>
  ) : (
    <>
      <Navigate to="/" replace />
    </>
  );
}
export default ProtectedAuth;
