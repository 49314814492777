import Logo from "./Logo.png"
import gamebg from "./gamebg1.svg"
import plane from "./sprite4.png"
import sprite2 from "./sprite2.png"
import sprite3 from "./sprite3.png"
import Inc from "./Inc.png"
import Dec from "./Dec.png"
import GameLoader from "./GameLoader.png"
import BlurryBg from "./blurrybg.png"
import curve from "./curve1.png"
import horDots from "./horDots.png"
import verDots from "./verDots.png"

export const Images={
    Logo,gamebg,plane,GameLoader,BlurryBg,curve,horDots,verDots,sprite2,sprite3,Inc,Dec
}