import React, { lazy } from 'react';
import ProtectedAuth from "./PrivateAuth";

const Main = lazy(() => import('../Screens/Main/Main'));




export const PrivateAuth = () => {
  return [
    {
      element: <ProtectedAuth />,
      children: [
        {
          path: "/main",
          element: <Main />,
        },

        
      ],
    },
  ];
};
