import React, { useState } from "react";
import { Images } from "../../Assets";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Button, Form, Input } from "antd";
import { dispatchtoast, mobilePattern } from "../../Utils";
import { userLoginMobile } from "../../ApiServices/Apifun";
import {  useNavigate } from "react-router-dom";

const Login = () => {
  const [btnLoading, setBtnLoading] = useState(false);
//   const [verifyToken, setVerifyToken] = useState(null);
  const navigate = useNavigate();
  const handleFinish = (val) => {
    setBtnLoading(true);
    userLoginMobile(val)
      .then((res) => {
        if (res.status) {
          navigate("/verify-otp", {
            state: {
              mobile: val.mobile,
              token: res.verificationToken,
            },
          });
          dispatchtoast(res.message);
          // setVerifyToken(res.verificationToken)
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setBtnLoading(false));
  };
  return (
    <>
      <div className="flex justify-center items-center h-screen">
        <div className="bg-[#E14342] rounded-sm shadow-md shadow-red-300  px-8 py-4">
          <div className="flex mt-4 justify-center items-center">
            <LazyLoadImage src={Images.Logo} className="w-20" alt="logo" />
          </div>
          <div>
            <Form onFinish={(val) => handleFinish(val)}>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: (
                      <p className="text-red-900">Enter Mobile Number</p>
                    ),
                  },
                  {
                    pattern: mobilePattern,
                    message: (
                      <p className="text-red-900">Enter Valid Mobile Number</p>
                    ),
                  },
                ]}
                name={"mobile"}
                className="w-full"
                label={<p className="font-semibold text-white p-0">Mobile</p>}
                labelCol={{ span: 24 }}
              >
                <Input
                  placeholder="Enter Mobile Number"
                  className="border-none hover:border-none"
                />
              </Form.Item>
              {/* {verifyToken && (
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: <p className="text-red-900">Enter Password</p>,
                    },
                  ]}
                  name={"password"}
                  className="w-full"
                  label={
                    <p className="font-semibold text-white p-0">Password</p>
                  }
                  labelCol={{ span: 24 }}
                >
                  <Input
                    placeholder="Enter Password"
                    className="border-none hover:border-none"
                  />
                </Form.Item>
              )} */}
              <Form.Item className="w-full">
                <Button
                  loading={btnLoading}
                  htmlType="submit"
                  style={{ background: "#181C27", color: "white" }}
                  rootClassName="bg-primary hover:bg-primary"
                  className="w-full mt-4 bg-primary shadow-none hover:text-white text-white border-none"
                >Login
                  {/* {verifyToken ? "Login" : "Send Otp"} */}
                </Button>
              </Form.Item>
              <div className="flex justify-center select-none items-center">
                <p className="text-white">
                  Don't have an account?{" "}
                  <span
                    onClick={() => navigate("/register")}
                    className="cursor-pointer"
                  >
                    Register
                  </span>
                </p>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
