import Login from "../Screens/Auth/Login";
import Register from "../Screens/Auth/Register";
import VerifyOtpScreen from "../Screens/Auth/VerifyOtp";
import PublicAuth from "./PublicAuth";

export const PublicRoutes = () => {
  return [
    {
      element: <PublicAuth />,
      children: [
        {
          path: "/",
          element: <Login />,
        },
        {
          path: "/register",
          element: <Register />,
        },
        {
            path: "/verify-otp",
            element: <VerifyOtpScreen />,
          },
      ],
    },
  ];
};
